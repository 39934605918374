<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-img
        src="@/assets/reward_screen_shot.png"
        width="414"
        height="234"
        style="display: block; margin-left: auto; margin-right: auto;"
      />
    </v-col>
    <v-col cols="12">
      <div class="ma-0 fn24" style="color: #06204B; font-weight: 600;">
        BIAH Event Evaluation
      </div>
    </v-col>
    <v-col cols="12" class="py-0">
      <span class="fn14">
        Thank you for attending ‘ FLEXcitement 2021” Let’s do the questionnaire
        and collect 10 points
      </span>
    </v-col>
    <v-col cols="12">
      <v-divider class="my-2"></v-divider>
    </v-col>
    <v-row
      align="center"
      justify="center"
      style="color: #1A347F;"
      class="px-4 fn16"
    >
      <v-form ref="form" v-model="valid">
        <v-col cols="12">
          <div>How did you hear about FLEXcitement?</div>
          <v-text-field v-model="hear" outlined hide-details />
        </v-col>
        <v-col cols="12">
          <div>What session did you like best?</div>
          <v-text-field v-model="best" outlined hide-details />
        </v-col>
        <v-col cols="12">
          <div>What session did you like the least?</div>
          <v-text-field v-model="least" outlined hide-details />
        </v-col>
        <v-col cols="12">
          <div>What was your favourite thing about the event?</div>
          <v-text-field v-model="favourite" outlined hide-details />
        </v-col>
        <v-col cols="12">
          <div>Do you have any other feedback?</div>
          <v-text-field v-model="feedback" outlined hide-details />
        </v-col>
        <v-col cols="12">
          <v-dialog
            v-model="dialog"
            persistent
            :overlay-opacity="0.9"
            max-width="375"
            width="375"
          >
            <a-icon
              type="close"
              class="white--text text-right"
              @click="dialog = false"
            />
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                outlined
                dark
                x-large
                v-bind="attrs"
                v-on="on"
                class="font-weight-bold rounded-lg fn16"
                style="background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
              >
                ตกลง
              </v-btn>
            </template>
            <v-card class="rounded-lg pa-4" max-height="416" height="416">
              <div
                class="fn24-weight7 text-center mt-4 px-4"
                style="color: #07204B;"
              >
                Thank you for Feedback!
              </div>
              <div class="px-0 mt-6 mx-4">
                <img
                  src="@/assets/BI.png"
                  align="left"
                  justify="left"
                  class="mt-n1"
                />
                <span class="fn22-weight7 ml-2" style="color: #1A347F;"
                  >You earn 10 points</span
                >
              </div>
              <div class="mt-10 fn14" style="color: #4F4F4F;">
                <span
                  >Our staff will review your activity and assign the points to
                  your account within 24 hours.</span
                >
              </div>
              <v-row no-gutters class="mt-10">
                <v-col cols="12" class="pa-0">
                  <v-btn
                    outlined
                    dark
                    block
                    x-large
                    class="text-capitalize rounded-lg fn16"
                    style="background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
                    @click="submit"
                  >
                    Back to Homepage
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-col>
      </v-form>
    </v-row>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      dialog: false,
      hear: "",
      best: "",
      least: "",
      favourite: "",
      feedback: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Catedata = JSON.parse(Decode.decode(localStorage.getItem("Catedata")));
    console.log("Catedata", Catedata);
    this.id = Catedata.id;
    this.categoryname = Catedata.category;
    this.categoryDescription = Catedata.description;
    this.activeFlag = Catedata.activeFlag;
    this.showImage = Catedata.categoryImg;
    this.pic1 = Catedata.categoryImg;
    this.picture_1 = Catedata.categoryImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCategory");
    },
    async submit() {
      this.$router.push("ManageRewards");
      // if (this.$refs.createForm.validate(true)) {
      //   const data = {
      //     id: this.id,
      //     category: this.categoryname,
      //     description: this.categoryDescription,
      //     activeFlag: this.activeFlag,
      //     categoryImg: this.pic1,
      //   };
      //   var user = JSON.parse(
      //     Decode.decode(localStorage.getItem("userBiData"))
      //   );
      //   const auth = {
      //     headers: {
      //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      //     },
      //   };
      //   const response = await this.axios.put(
      //     `${process.env.VUE_APP_API}/categories/` + this.id,
      //     data,
      //     auth
      //   );
      //   console.log("createCate", response);
      //   console.log(response.data.response_status);
      //   if (response.data.response_status == "SUCCESS") {
      //     this.$swal.fire({
      //       icon: "success",
      //       text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.$router.push("ManageCategory");
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       text: `สร้างหมวดหมู่สินค้าไม่สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      // }
    },
  },
};
</script>
